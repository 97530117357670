import React, {useState} from 'react'
import EventsNavbar from '../components/EventsComponents/EventsNavbar'
import EventsSidebar from '../components/EventsComponents/EventsSidebar';
import InfoSection from '../components/InfoSection';
import { eventsObjOverview, eventsObjCollaborations, eventsObjProblemSolving, eventsObjSocial, eventsObjCharity, eventsObjAlgothon} from '../components/InfoSection/Data';
import Footer from '../components/Footer';
import SmallInfoSection from '../components/SmallInfoSection';

const Events = () => {
  
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };
  
    return (
    <>
        <EventsSidebar isOpen={isOpen} toggle={toggle}/>
        <EventsNavbar toggle={toggle}/>
        <div style={{background: "black", height: "80px"}}></div>
        <InfoSection {...eventsObjOverview}/>
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1em",
        }}>
            <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Australia%2FBrisbane&showPrint=0&mode=AGENDA&src=Y19lNzQ1NGEwMmU3ZTdiNjhmZmFiOGVlZGU0Zjk0NjM3MjE3Y2VmZjI5NDVjZDQwZmYyMzI3YzQ5Y2NjZWY1NWE1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23D81B60" style={{borderWidth: "0"}} width="80%" height="600" frameborder="0" scrolling="no"></iframe>

        </div>




        {/*<InfoSection {...eventsObjAlgothon} />*/}
        <SmallInfoSection {...eventsObjProblemSolving}/>
        <SmallInfoSection {...eventsObjSocial}/>
        <SmallInfoSection {...eventsObjCollaborations}/>
        <SmallInfoSection {...eventsObjCharity}/>
        <Footer></Footer>
    </>
  )
}

export default Events