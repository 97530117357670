import './App.css';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import Home from './pages';
import SignInPage from './pages/signin';
import { AuthContextProvider } from './context/AuthContext';
import User from './pages/user';
import Protected from './components/Protected';
import SignUpPage from './pages/signup';
import SpinnerPage from './pages/spinner';
import CompProtected from './components/CompProtected';
import TradingComp from './pages/tradingComp';
import PokerTimer from './pages/pokerTimer';
import Algojam from './pages/algojam';
import Team from './pages/team';
import Sponsors from './pages/sponsors';
import Events from './pages/events';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const ANALYTICS_ID = 'G-JP5Y51E4TT' // OUR GOOGLE ANALYTICS ID
ReactGA.initialize(ANALYTICS_ID)

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
    <Router>
      <AuthContextProvider>
      <Routes>
        <Route path='/' element={<Home />} exact />
        <Route path='/signin' element={<SignInPage />} />
        <Route path='/signup' element={<SignUpPage />} />
        <Route path='/spinner' element={<SpinnerPage />} />
        <Route path='/poker_timer' element={<PokerTimer />} />
        <Route path='/team' element={<Team />} />
        <Route path='/sponsors' element={<Sponsors />} />
        <Route path='/events' element={<Events />} />
        <Route path='/algojam' element={<Algojam />} />
        <Route path='/user' element={
          <Protected>
            <User />
          </Protected>
        } />
        <Route path='/trading_comp' element={
          <CompProtected competitionName={"testComp1"}>
              <TradingComp />
          </CompProtected>
        } />
      </Routes>
      </AuthContextProvider>
    </Router>
    </>
  );
}

export default App;
