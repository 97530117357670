import React, {useState} from 'react'
import TeamNavbar from '../components/TeamComponents/TeamNavbar'
import TeamSidebar from '../components/TeamComponents/TeamSidebar';
import InfoSection from '../components/InfoSection';
import SmallInfoSection from '../components/SmallInfoSection';
import TeamSection from '../components/TeamSection';
import { teamGrigorC, teamLewisH, teamLaurenH, teamObjAbout, teamObjHistory, teamDarrenL, teamSiddeshK, teamMarcoZ} from '../components/InfoSection/Data';
import Footer from '../components/Footer';
import {Heading} from '../components/InfoSection/InfoElements';
import {team, teamLaraJ, teamLeoS, teamMaxG, teamAryanG, teamAngusG ,teamYousefA, teamDomB, teamAmyP, teamCalebM, teamJakeW} from '../components/InfoSection/Data';

const Team = () => {
  
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };
  
    return (
    <>
        <div style={{background: "black", height: "80px"}}></div>
        <TeamSidebar isOpen={isOpen} toggle={toggle}/>
        <TeamNavbar toggle={toggle}/>
        <InfoSection {...teamObjAbout}/>
        <SmallInfoSection {...teamMaxG}/>
        <SmallInfoSection {...teamJakeW}/>
        <SmallInfoSection {...teamAryanG}/>
        <SmallInfoSection {...teamLewisH}/>
        <SmallInfoSection {...teamLaurenH}/>
        <SmallInfoSection {...teamSiddeshK}/>
        <SmallInfoSection {...teamDarrenL}/>
        <SmallInfoSection {...teamGrigorC}/>
        <SmallInfoSection {...teamMarcoZ}/>
        <SmallInfoSection {...teamAngusG}/>
        <InfoSection {...teamObjHistory}/>
        <Footer></Footer>
    </>
  )
}

export default Team